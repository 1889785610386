import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "../Content/menu.scss?v=1";
import axios from "../Utils/axios";
import $ from "jquery";
import { Alert } from "bootstrap";

export function Header() {
  const [list, setList] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  React.useEffect(() => {
    getDataList();
    initJQUERY();
  }, []);

  function initJQUERY() {
    // $(".mnsbmn")
    //   .off("click")
    //   .on("click", function (e) {
    //     //alert(1);
    //     $(this).toggleClass("open");
    //     $(this).parent().find(".submn-cont").toggleClass("open");
    //   });
    $("body").on("click", ".mnsbmn", function () {
      $(this).toggleClass("open");
      $(this).parent().find(".submn-cont").toggleClass("open");
    });
    $("body").on("click", ".submn", function () {
      $(".mnsbmn").removeClass("open");
      $(".submn-cont").removeClass("open");
    });
  }

  let getDataList = async function () {
    axios
      .get("/gallery/menusubmenu")
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <NavLink className="nav" to="/home"></NavLink>

      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        id="active"
      />
      <label htmlFor="active" className="menu-btn">
        <i className="fas fa-bars"></i>
      </label>
      <div className="wrapper">
        {list.map((menu, index) => (
          <div className="wrapper-contt" key={index}>
            {menu.id == "2" || menu.id == "3" ? (
              <div className="mnsbmn">
                {menu.title} <i className="fa-solid fa-chevron-right"></i>
              </div>
            ) : (
              <NavLink
                className="mn"
                onClick={() => handleChange("")}
                to={"/" + menu.type}
              >
                {menu.title}
              </NavLink>
            )}
            <>
              <div className="submn-cont">
                {menu.cat
                  ? menu.cat.map((submn, index2) => (
                      <>
                        <Link
                          id=""
                          to={"/" + menu.type + "/?cat=cat_" + submn.id}
                          onClick={() => handleChange("")}
                          className="submn"
                        >
                          {submn.name}
                        </Link>
                      </>
                    ))
                  : ""}
              </div>
            </>
          </div>
        ))}
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <div className="footer">
      <a
        href="https://www.instagram.com/n_photography___/"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-brands fa-instagram"></i>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100075556099277&mibextid=LQQJ4d"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-brands fa-square-facebook"></i>
      </a>
      <a
        href="https://www.tiktok.com/@n.photography?_t=8YzLDQ44ki1&_r=1"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa-brands fa-tiktok"></i>
      </a>
      <a href="tel:558004447">
        <i className="fa-solid fa-phone"></i>
      </a>
    </div>
  );
}
