import "./Content/styles.scss?v=1";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Header, Footer } from "./Pages/HeaderFooter";
import Home from "./Pages/Home";
import Gallery from "./Pages/Gallery";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Price from "./Pages/Price";
import Odds from "./Pages/Odds";

// http://realphototbilisi.com
export default function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/odds" element={<Odds />} />
            <Route exact path="/cat" element={<Gallery />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/price" element={<Price />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}
