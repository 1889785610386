import React from "react";
import Lightgalleryy from "../Utils/lightgallery/Lightgallery";
import Owl from "../Utils/owl/Owl";
import Catlist from "./Catlist";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Gallery() {
  let query = useQuery();
  let cat = query.get("cat");
  return (
    <div>
      <div className="in-content-slider">
        <Owl images="slider_cat" />
      </div>
      <Catlist path={cat} />
      <center>
        {cat ? <Lightgalleryy imagesid={cat} /> : "აიღჩიეთ კატეგორია"}
      </center>
    </div>
  );
}
