import React from "react";

class Odd extends React.Component {
  state = {
    class: "box"
  };

  componentWillReceiveProps(newProps) {
    /**/
    if (newProps.name > this.props.name) {
      this.setState({ class: "box up" });
    } else if (newProps.name < this.props.name) {
      this.setState({ class: "box down" });
    } else {
      this.setState({ class: "box" });
    }
  }

  render() {
    return (
      <div className={this.state.class}>
        <span>{this.props.id}</span>
        {this.props.name}
      </div>
    );
  }
}

export default Odd;
