import React, { useState, useEffect } from "react";
import Owl from "../Utils/owl/Owl";
import Gv from "./Gv";

export default function About() {
  return (
    <div>
      <div className="in-content-slider">
        <Owl images="slider_about" />
      </div>
      <Gv gvid="about_0" />
    </div>
  );
}
