import React, { useState, useEffect } from "react";
const url = "https://nphotography.ge/";
export function fetchD(n, w, h) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    getDataList(n, w, h);
  }, []);
  let getDataList = async function (n, w, h) {
    fetch(url + "img/?n=" + n + "&w=" + w + "&h=" + h, {
      method: "GET"
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          return "error";
        }
      );
  };

  return data;
}
export function fetchUrl(url) {
  const [data, setData] = useState([]);
  useEffect(() => {
    getDataList();
  }, []);
  function getDataList() {
    fetch(url, {
      method: "GET"
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          //console.log("test", result);
        },
        (error) => {
          return "error";
        }
      );
  }

  return data;
}

export function test(n) {
  return n;
}
