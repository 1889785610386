import React, { useState, useEffect } from "react";
import axios from "../Utils/axios";

export default function Gv({ gvid }) {
  const [message, setMessage] = useState("");

  useEffect(() => {
    getData(gvid);
  }, [gvid]);

  let getData = async function (gvid) {
    // console.log(gvid);
    axios
      .get("/gallery/gv/" + gvid)
      .then((response) => {
        setMessage(response.data[0].text);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="gvtxt" dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
}
