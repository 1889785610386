import React from "react";
import Owl from "../Utils/owl/Owl";
import { fetchUrl } from "../Utils/fetch";
import { Link } from "react-router-dom";

export default function Home() {
  React.useEffect(() => {}, []);

  let dataimg = fetchUrl(
    "https://nphotography.ge/laravel/ecom/api/gallery/homepage"
  );

  //console.log("dataimg", dataimg);

  return (
    <div>
      <div className="main-content-slider">
        <Owl images="slider_main" />
        <svg className="arrows">
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
          <path className="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>
      </div>

      <div className="home_list_cont">
        {dataimg
          ? dataimg.map((item) => {
              return (
                <div key={item.id} className="home_list">
                  <div className="home_list_title">{item.name}</div>
                  <img
                    src={
                      "https://nphotography.ge/laravel/ecom/" +
                      item.image_rand[0].img_path
                    }
                    alt=""
                  />
                  <div className="home_list_bt_cont">
                    <Link
                      id=""
                      to={"/cat/?cat=cat_" + item.id}
                      className="home_list_bt"
                    >
                      ფოტოები
                    </Link>
                    <Link
                      id=""
                      to={"/price/?cat=cat_" + item.id}
                      className="home_list_bt"
                    >
                      ფასები
                    </Link>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
}
