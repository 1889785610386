import React, { useState, useEffect } from "react";
import Owl from "../Utils/owl/Owl";
import axios from "../Utils/axios";
import Gv from "./Gv";
import Catlist from "./Catlist";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Price() {
  let query = useQuery();
  let cat = query.get("cat");

  return (
    <div>
      <div className="in-content-slider">
        <Owl images="slider_price" />
      </div>
      <Catlist path={cat} />

      {cat ? <Gv gvid={cat} /> : ""}
    </div>
  );
}
